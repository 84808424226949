import jwt_decode from 'jwt-decode';

const SESSION_STORAGE_KEY = 'email';
const getCognitoUrl = (route: string) =>
  `https://nova-scavenger.auth.us-west-2.amazoncognito.com/${route}?client_id=2edeb0a6rlrep2k50rhqqodo4p&response_type=token&scope=email+openid+phone&redirect_uri=${process.env.REACT_APP_FRONTEND_URL}`;

export const getEmailFromToken = (): string => {
  const emailFromSessionStorage = sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (emailFromSessionStorage) {
    return emailFromSessionStorage;
  }

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const fragment = url.hash;
  if (!fragment) {
    return '';
  }

  const idTokenStart = fragment.indexOf('id_token=') + 'id_token='.length;
  const idTokenEnd =
    fragment.indexOf('&', idTokenStart) !== -1
      ? fragment.indexOf('&', idTokenStart)
      : fragment.length;
  const idToken = fragment.substring(idTokenStart, idTokenEnd);
  const decoded = jwt_decode(idToken) as { email: string };
  const email = decoded.email;
  sessionStorage.setItem(SESSION_STORAGE_KEY, email);
  return email;
};

export const login = () => {
  window.location.replace(getCognitoUrl('login'));
};

export const logout = () => {
  sessionStorage.removeItem(SESSION_STORAGE_KEY);
  window.location.replace(getCognitoUrl('logout'));
};

export const alphabeticallySort = (
  a: [string, string],
  b: [string, string]
) => {
  if (a[1] < b[1]) {
    return -1;
  }
  if (b[1] < a[1]) {
    return 1;
  }
  return 0;
};
