import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { FunFact } from './types';
import { submitResponse } from './api';
import { humanReadableAnswerList } from './const';

type FunFactModalProps = {
  open: boolean;
  availableOptions: string[];
  guesses: FunFact[];
  openFunFact: FunFact | undefined;
  loggedInEmail: string;
  handleClose: () => void;
  setGuesses: (param: FunFact[]) => void;
};

const FunFactModal: React.FC<FunFactModalProps> = ({
  open,
  availableOptions,
  openFunFact,
  handleClose,
  guesses,
  loggedInEmail,
  setGuesses,
}) => {
  const [currentGuess, setCurrentGuess] = useState('');

  useEffect(() => setCurrentGuess(openFunFact?.guess || ''), [openFunFact]);

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentGuess(event.target.value as string);
  };

  if (!openFunFact) {
    return <></>;
  }

  const onClose = () => {
    setGuesses(
      guesses.map((row) =>
        openFunFact.funFact === row.funFact
          ? { ...row, guess: currentGuess }
          : row
      )
    );
    submitResponse({
      email: loggedInEmail,
      funFactId: openFunFact.id,
      guess: currentGuess, // as email
    });
    setCurrentGuess('');
    // API call to update guess
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 350,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
        }}
      >
        <Grid container>
          <Grid xs={11} />
          <Grid xs={1}>
            {/* <Button onClick={onClose}>X</Button> */}
            {/* <div onClick={onClose}>X</div> */}
          </Grid>
          <Grid xs={12}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {openFunFact.funFact}
            </Typography>
          </Grid>
          <Grid sx={{ marginTop: 2 }} xs={12}>
            <InputLabel id="demo-simple-select-label">Guess</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentGuess}
              label="Guess"
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={undefined}>None</MenuItem>
              {[openFunFact?.guess || '', ...availableOptions]
                .sort((a, b) =>
                  humanReadableAnswerList[a].localeCompare(
                    humanReadableAnswerList[b]
                  )
                )
                .filter(Boolean)
                .map((email, index) => (
                  <MenuItem key={index} value={email}>
                    {humanReadableAnswerList[email]}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default FunFactModal;
