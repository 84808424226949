const url = process.env.REACT_APP_BACKEND_URL as string;

export const getAllResponses = async (email: string) => {
  const res = await fetch(`${url}/${email}`, {
    method: 'GET',
  });

  const body = await res.json();
  return body;
};

type SubmitResponseParams = {
  email: string;
  funFactId: string;
  guess: string;
};

export const submitResponse = async ({
  email,
  funFactId,
  guess,
}: SubmitResponseParams) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, funFactId, guess }),
  });

  const body = await res.json();
  return body;
};
