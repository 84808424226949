import React from 'react';
import { Typography, Card, Unstable_Grid2 as Grid } from '@mui/material';
import type { FunFact } from './types';
import { humanReadableAnswerList, categoryColours } from './const';

export const FunFactRow: React.FC<{
  funFact: FunFact;
  answer: string;
  index: number;
  categoryName?: string;
  handleOpen: () => void;
  setOpenFunFact: (funFact: FunFact) => void;
}> = ({ funFact, answer, index, handleOpen, categoryName, setOpenFunFact }) => (
  <>
    <Grid xs={8} md={10}>
      <Card
        variant="outlined"
        sx={{
          height: '100%',
          borderWidth: 1,
          borderColor: categoryName ? categoryColours[categoryName] : '#1976d2',
          borderRightWidth: 0,
        }}
      >
        <Typography sx={{ padding: 1 }}>
          {funFact.funFact}.&nbsp;
          <strong>Answer: {humanReadableAnswerList[answer]}</strong>
        </Typography>
      </Card>
    </Grid>
    <Grid xs={4} md={2}>
      <Card
        variant="outlined"
        className="name-input"
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          wordWrap: 'break-word',
          borderWidth: 1,
          borderColor: categoryName ? categoryColours[categoryName] : '#1976d2',
          borderLeftWidth: 0,
          backgroundColor: funFact.guess
            ? funFact.guess === answer
              ? 'lightgreen'
              : 'red'
            : 'white',
        }}
        onClick={() => {
          setOpenFunFact(funFact);
          handleOpen();
        }}
      >
        <Typography
          align="center"
          sx={{
            overflowWrap: 'break-word',
            width: '100%',
            padding: 1,
          }}
        >
          {funFact.guess
            ? humanReadableAnswerList[funFact.guess as string]
            : '?'}
        </Typography>
      </Card>
    </Grid>
  </>
);

export default FunFactRow;
