import React, { useState, useEffect, useMemo } from 'react';
import { Unstable_Grid2 as Grid, Paper } from '@mui/material';
import FunFactRow from './FunFactRow';
import FunFactModal from './FunFactModal';
import FunFactGroup from './FunFactCategory';
import { FunFact } from './types';
import { getAllResponses } from './api';
import { alphabeticallySort } from './utils';

type ScavengerHuntMainProps = {
  email: string;
  search: string;
};

const ScavengerHuntMain: React.FC<ScavengerHuntMainProps> = ({
  email,
  search,
}) => {
  const [guesses, setGuesses] = useState<FunFact[]>([]);
  const [categoryList, setCategoryList] = useState<Record<string, string>>({});
  const [answerList, setAnswerList] = useState<Record<string, string>>({});
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [openFunFact, setOpenFunFact] = useState<FunFact>();

  useEffect(() => {
    getAllResponses(email).then(
      ({ currentGuesses, questionList, categoryList, answerList, options }) => {
        setGuesses(
          Object.entries(questionList as [string, string])
            .sort(alphabeticallySort)
            .map(
              ([id, funFact], index) =>
                ({
                  id,
                  index,
                  funFact,
                  guess: currentGuesses[id],
                } as FunFact)
            )
        );
        setCategoryList(categoryList);
        setOptions(options);
        setAnswerList(answerList);
      }
    );
  }, []);

  const handleOpen = () => setOpen(true);

  const availableOptions: string[] = useMemo(() => {
    const unavailableGuesses = guesses
      .map((guess) => guess.guess)
      .filter(Boolean);
    return options.filter((name) => !unavailableGuesses.includes(name)).sort();
  }, [guesses]);

  const groupedGuesses = useMemo(() => {
    if (
      guesses.length === 0 ||
      guesses.length !== Object.keys(categoryList).length
    ) {
      return [];
    }

    return guesses.reduce((acc, guess) => {
      if (!acc[categoryList[guess.id] as string]) {
        acc[categoryList[guess.id] as string] = [];
      }

      acc[categoryList[guess.id] as string].push(guess);
      return acc;
    }, {} as Record<string, FunFact[]>);
  }, [guesses, categoryList]);

  return (
    <>
      {search ? (
        <Grid
          sx={{ padding: 1 }}
          container
          rowSpacing={0.5}
          columnSpacing={0.5}
        >
          {guesses
            .filter((funFact) =>
              funFact.funFact.toLowerCase().includes(search.toLowerCase())
            )
            .map((funFact) => (
              <FunFactRow
                key={funFact.id}
                answer={answerList[funFact.id]}
                funFact={funFact}
                index={funFact.index}
                handleOpen={handleOpen}
                setOpenFunFact={setOpenFunFact}
              />
            ))}
        </Grid>
      ) : (
        Object.entries(groupedGuesses).map(([group, groupGuesses]) => (
          <FunFactGroup
            groupName={group}
            group={groupGuesses as FunFact[]}
            answers={answerList}
            handleOpen={handleOpen}
            setOpenFunFact={setOpenFunFact}
          />
        ))
      )}
      <FunFactModal
        open={open}
        openFunFact={openFunFact}
        availableOptions={availableOptions}
        handleClose={() => {
          setOpen(false);
          setOpenFunFact(undefined);
        }}
        loggedInEmail={email}
        guesses={guesses}
        setGuesses={(newGuesses: FunFact[]) => setGuesses(newGuesses)}
      />
    </>
  );
};

export default ScavengerHuntMain;
