export const humanReadableAnswerList = {
  'nikki.cross@novacredit.com': 'Nikki',
  'zach@novacredit.com': 'Zach R',
  'flora@novacredit.com': 'Flora',
  'cameron@novacredit.com': 'Cameron',
  'mike.petkun@novacredit.com': 'Mike P',
  'oren@novacredit.com': 'Oren',
  'andy@novacredit.com': 'Andy',
  'adam@novacredit.com': 'Adam',
  'tracey@novacredit.com': 'Tracey',
  'matt.alsheikh@novacredit.com': 'Matt A',
  'erin@novacredit.com': 'Erin',
  'ben.harris@novacredit.com': 'Ben H',
  'alyssa@novacredit.com': 'Alyssa',
  'mike.groves@novacredit.com': 'Mike G',
  'hunter@novacredit.com': 'Hunter',
  'dan.laughlin@novacredit.com': 'Dan L',
  'collin@novacredit.com': 'Collin',
  'lulu@novacredit.com': 'Lulu',
  'dave@novacredit.com': 'Dave',
  'megna@novacredit.com': 'Megna',
  'abby@novacredit.com': 'Abby',
  'jenni@novacredit.com': 'Jenni',
  'lindsey@novacredit.com': 'Lindsey',
  'viffy@novacredit.com': 'Viffy',
  'dan@novacredit.com': 'Dan S',
  'camila@novacredit.com': 'Camila',
  'miguel@novacredit.com': 'Miguel',
  'grace@novacredit.com': 'Grace',
  'dhivyaa@novacredit.com': 'Dhivyaa',
  'addy@novacredit.com': 'Addy',
  'karl@novacredit.com': 'Karl',
  'halla@novacredit.com': 'Halla',
  'jordan@novacredit.com': 'Jordan',
  'robert.xu@novacredit.com': 'Robert',
  'rafael@novacredit.com': 'Rafa',
  'matt@novacredit.com': 'Matt D',
  'priyanshi@novacredit.com': 'Priyanshi',
  'samantha@novacredit.com': 'Sam',
  'kevin.liu@novacredit.com': 'Kevin',
  'jinting@novacredit.com': 'Jinting',
  'zach.eltayyeb@novacredit.com': 'Zach E',
  'misha@novacredit.com': 'Misha',
  'elena@novacredit.com': 'Elena',
  'jeff@novacredit.com': 'Jeff',
  'rylan@novacredit.com': 'Rylan',
  'jessie.chen@novacredit.com': 'Jessie',
  'jessica.kagansky@novacredit.com': 'Jessica',
  'sophie.heriot@novacredit.com': 'Sophie H',
  'rich@novacredit.com': 'Rich',
  'alice@novacredit.com': 'Alice',
  'lydia@novacredit.com': 'Lydia',
  'sophie@novacredit.com': 'Sophie C',
  'sima.baran@novacredit.com': 'Sima',
  'joseluis@novacredit.com': 'Jose Luis',
  'harris@novacredit.com': 'Harris',
  'meng@novacredit.com': 'Meng',
  'james.xue@novacredit.com': 'James X',
  'josh@novacredit.com': 'Josh',
  'tuvia@novacredit.com': 'Tuvia',
  'cloris@novacredit.com': 'Cloris',
  'gustavo@novacredit.com': 'Gustavo',
  'shiv@novacredit.com': 'Shiv',
  'philip@novacredit.com': 'Philip',
  'akaash@novacredit.com': 'Akaash',
  'tyrone@novacredit.com': 'Tyrone',
  'yacine@novacredit.com': 'Yacine',
  'chris.hansen@novacredit.com': 'Chris',
  'vivek@novacredit.com': 'Vivek',
  'clare@novacredit.com': 'Clare',
  'milton@novacredit.com': 'Milton',
  'danny@novacredit.com': 'Danny',
  'kwang@novacredit.com': 'Kwang',
  'pablo@novacredit.com': 'Pablo',
  'jonathan.kim@novacredit.com': 'Jonathan',
  'whitney@novacredit.com': 'Whitney',
  'james.peralta@novacredit.com': 'James P',
  Jon: 'Jon',
} as { [key: string]: string };

export const categoryColours = {
  Random: '#F1476F',
  Skills: '#5AC8FA',
  Superlatives: '#FFAA00',
  Animals: '#43A047',
  Travel: '#2196F3',
  Celebrity: '#FF5722',
  Smarties: '#6D4C41',
  Jobs: '#3F51B5',
};
