import React, { useState } from 'react';
import ScavengerHuntMain from './ScavengerHuntMain';
import AppBar from './AppBar';
import { getEmailFromToken, login } from './utils';

const App: React.FC = () => {
  const [search, setSearch] = useState('');
  const email = getEmailFromToken();

  if (!email) {
    login();
  }

  return (
    <>
      <AppBar search={search} setSearch={(value: string) => setSearch(value)} />
      <ScavengerHuntMain email={email} search={search} />
    </>
  );
};

export default App;
