import React from 'react';
import {
  AppBar as MuiAppBar,
  Typography,
  Toolbar,
  Button,
  SvgIcon,
  TextField,
} from '@mui/material';
import { logout } from './utils';

import { ReactComponent as NovaLogo } from './logo.svg';

type AppBarProps = {
  search: string;
  setSearch: (value: string) => void;
};

const AppBar: React.FC<AppBarProps> = ({ search, setSearch }) => (
  <MuiAppBar position="sticky">
    <Toolbar sx={{ display: 'flex' }}>
      <SvgIcon component={NovaLogo} viewBox="0 0 35 35" color="primary" />
      <Typography variant="h6" sx={{ marginLeft: 1 }}>
        Scavenger Hunt
      </Typography>
      <Button
        variant="text"
        sx={{ color: 'white', marginLeft: 'auto' }}
        onClick={logout}
      >
        Sign out
      </Button>
    </Toolbar>
    <TextField
      sx={{ background: 'white' }}
      variant="outlined"
      placeholder="Search"
      value={search}
      onChange={(event) => setSearch(event.target.value)}
    />
  </MuiAppBar>
);

export default AppBar;
