import React, { useState } from 'react';
import { Typography, Paper, Unstable_Grid2 as Grid, Icon } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import FunFactRow from './FunFactRow';
import type { FunFact } from './types';
import { categoryColours } from './const';

export const FunFactGroup: React.FC<{
  groupName: string;
  group: FunFact[];
  answers: Record<string, string>;
  handleOpen: () => void;
  setOpenFunFact: (funFact: FunFact) => void;
}> = ({ groupName, group, answers, handleOpen, setOpenFunFact }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <Paper
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 7,
          background: categoryColours[groupName],
          color: 'white',
        }}
        onClick={() => setIsExpanded((preState) => !preState)}
      >
        <Typography variant="h5">{groupName}</Typography>
        <Icon>{isExpanded ? <ExpandLess /> : <ExpandMore />}</Icon>
      </Paper>
      {isExpanded && (
        <Grid
          sx={{ padding: 1 }}
          container
          rowSpacing={0.5}
          columnSpacing={0.5}
        >
          {(group as FunFact[]).map((funFact) => (
            <FunFactRow
              key={funFact.id}
              funFact={funFact}
              answer={answers[funFact.id]}
              index={funFact.index}
              handleOpen={handleOpen}
              setOpenFunFact={setOpenFunFact}
              categoryName={groupName}
            />
          ))}
        </Grid>
      )}
    </>
  );
};

export default FunFactGroup;
